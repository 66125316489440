@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css?family=Muli&display=swap");

@layer components {
  * {
    box-sizing: border-box;
  }

  .SpanUp {
    color: #4cbfe5;

    transform: translateY(-30px);
  }

  @keyframes downToUpOpaque {
    0% {
      opacity: 0;
      transform: translateY(50%);
    }

    100% {
      opacity: 1;
      transform: translateY(0%);
    }
  }

  .animate-transformOpaque {
    animation-name: downToUpOpaque;
    animation-duration: 0.51s;
    animation-fill-mode: forwards;
  }
}
