@import url("https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700;900&family=Manrope:wght@300;400;500;600;700&family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .font-Montserrat {
    font-family: "Montserrat", sans-serif;
  }
  
  .font-Lato {
    font-family: "Lato", sans-serif;
  }
  
  .font-Manrope {
    font-family: "Manrope", sans-serif;
  }

  body {
    background-color: rgb(236, 236, 236);
  }
  
  .content::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }
  
  .content::-webkit-scrollbar-track {
    margin-top: 15px;
    margin-bottom: 15px;
    background-color: transparent;
  }
  
  .content::-webkit-scrollbar-thumb {
    background-color: #0a8ab1;
    border-radius: 100px;
  }

  .categList::-webkit-scrollbar {
    width: 4px;
  }
  
  .categList::-webkit-scrollbar-track {
    background-color: transparent;
    margin-top: 20px;
    margin-bottom: 12px;
  }
  
  .categList::-webkit-scrollbar-thumb {
    background-color: #acacac;
    border-radius: 100px;
  }

  .customScroller::-webkit-scrollbar {
    width: 4px;
  }
  
  .customScroller::-webkit-scrollbar-track {
    margin-top: 2px;
    margin-bottom: 2px;
    background-color: transparent;
  }
  
  .customScroller::-webkit-scrollbar-thumb {
    background-color: #a9a9a9ae;
    border-radius: 100px;
  }

  .toRight {
    display: block;
    animation: tole 0.4s forwards;
  }
  
  @keyframes tole {
    to {
      transform: translateX(0);
      opacity: 1;
    }
  }
}
