@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css?family=Muli:300,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@200;300;400;500;600;700;800;900;1000&display=swap");

* {
  box-sizing: border-box;
}

@layer utlities {
  input[type="number"] {
    box-shadow: 0 10px 10px -5px rgba(0, 0, 0, 0.25);
  }

  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    appearance: none;
    margin: 0;
    -webkit-appearance: none;
    -moz-appearance: textfield !important;
  }

  .font-Nsans {
    font-family: "Nunito Sans", sans-serif;
  }

  .passReset {
    animation: opaque forwards 2s;
  }

  @keyframes opaque {
    from {
      opacity: 0;
    }
  }
}
