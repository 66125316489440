@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css?family=Muli&display=swap");

@layer components {
  * {
    box-sizing: border-box;
  }

  .SpanUp {
    color: #4cbfe5;

    transform: translateY(-30px);
  }

  @keyframes opaque0ToDown {
    0% {
      opacity: 0;
      transform: rotateX(90deg) translateY(-80%);
    }

    100% {
      opacity: 1;
      transform: rotateX(0deg) translateY(0);
    }
  }

  .animate-transformOpaqueUp {
    animation-name: opaque0ToDown;
    animation-duration: 0.61s;
    animation-fill-mode: forwards;
  }
}
